import { Component, OnInit, OnDestroy, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../../services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import menuItems, { IMenuItem } from 'src/app/shared/constants/menu';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html'
})
export class TopnavComponent implements OnInit, OnDestroy {

  isMultiColorActive = true;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'Sarah Cortney';
  currentLanguage: string;
  isSingleLang;
  menuItems: IMenuItem[] = menuItems;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  licenseFile = undefined;
  modalRef: BsModalRef;
  fileUpdModalRef: BsModalRef;
  isLicenseValid: boolean = true;
  config = {
    url: 'https://httpbin.org/post',
    thumbnailWidth: 100,
    maxFiles: 1,
    parallelUploads: 1,
    acceptedFiles: 'text/plain,.txt,application/json,.json,.xvl',
    clickable: true,
    uploadMultiple: false,
    // tslint:disable-next-line: max-line-length
    previewTemplate: '<div class="dz-preview dz-file-preview mb-3"><div class="d-flex flex-row "><div class="p-0 w-30 position-relative"><div class="dz-error-mark"><span><i></i></span></div><div class="dz-success-mark"><span><i></i></span></div><div class="preview-container"><img data-dz-thumbnail class="img-thumbnail border-0" /><i class="simple-icon-doc preview-icon" ></i></div></div><div class="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative"><div><span data-dz-name></span></div><div class="text-primary text-extra-small" data-dz-size /><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div><div class="dz-error-message"><span data-dz-errormessage></span></div></div></div><a href="#/" class="remove" data-dz-remove><i class="glyph-icon simple-icon-trash"></i></a></div>'
  };
  _top_nav_bar = {
    licenseValidityStr: '',
    _btn_disable: false,
  }
  fileToUpload: any;
  fileText: string = "Select License File";
  fileMsg: string = '';
  public modalConfig: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
  };
  fData: any = undefined;
  filerArr: any[] = [];
  constructor(
    public global: GlobalService,
    private sidebarService: SidebarService,
    public router: Router,

    private modalService: BsModalService,
    // private langService: LangService
    ) {
    // this.languages = this.langService.supportedLanguages;
    // this.currentLanguage = this.langService.languageShorthand;
    // this.isSingleLang = this.langService.isSingleLang;

    this.isDarkModeActive = this.getColor().indexOf('dark') > -1 ? true : false;
  }
  onDarkModeChange(event) {
    let color = this.getColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    localStorage.setItem(environment.themeColorStorageKey, color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  getColor() {
    return localStorage.getItem(environment.themeColorStorageKey)
      ? localStorage.getItem(environment.themeColorStorageKey)
      : environment.defaultColor;
  }

  fullScreenClick() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  // onLanguageChange(lang) {
  //   this.langService.language = lang.code;
  //   this.currentLanguage = this.langService.languageShorthand;
  // }

  ngOnInit() {
    this.displayName = 'john doe';
    // var str = this.global.convertSeconds(parseInt(this.licenseFile.ExpiresOn));
    // this.licenseFile.ExpiresOn = this.global.convertSeconds(parseInt(this.licenseFile.ExpiresOn)).toLocaleDateString();
    // this.licenseFile.LicenseIssueDate = this.global.convertSeconds(parseInt(this.licenseFile.LicenseIssueDate)).toLocaleDateString();
    // if (localStorage.getItem('userSCS') !== null) {
    //   this.displayName = JSON.parse(localStorage.getItem('userSCS')).userloginid;
    // }
    this.subscription = this.sidebarService.getSidebar().subscribe(
      res => {
        this.sidebar = res;
      },
      err => {
        console.error(`An error occurred: ${err.message}`);
      }
    );


  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  menuButtonClick = (e: { stopPropagation: () => void; }, menuClickCount: number, containerClassnames: string) => {
    if (e) { e.stopPropagation(); }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (event: { stopPropagation: () => void; }, containerClassnames: string) => {
    if (event) { event.stopPropagation(); }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut() {
    localStorage.clear();
    this.router.navigate(['/login']);
    this.menuItems.map(it => {
      it.bool = false;
      if (it.subs !== undefined) {
        if (it.subs.length > 0) {
          it.subs.map(subObj => subObj.bool = false);
        }
      }
    })
    // setTimeout(() => {
    //   window.location.reload();
    // }, 50);
    // localStorage.removeItem(this.global.settings.PWA_USER);
    // this.authService.signOut().subscribe(() => {
    // });
  }

  searchKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) { input.classList.remove('mobile-view'); }
      this.searchKey = '';
    }
  }

  searchAreaClick(event) {
    event.stopPropagation();
  }
  searchClick(event) {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search() {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate(['/app/pages/miscellaneous/search'], { queryParams: { key: this.searchKey.toLowerCase().trim() } });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event) {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) { input.classList.remove('mobile-view'); }
    this.searchKey = '';
  }
  routeToProfile() {
    this.router.navigate(['/profile']);
  }
}

import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import * as data from "../../../assets/settings.json";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = environment.isMultiColorActive;
  constructor(
    private renderer: Renderer2,
  ) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.renderer.addClass(document.body, "show");
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, "default-transition");
    }, 1500);
  }
}

import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
// import { GlobalConstants } from '../constants/global.constants';
import { GlobalConstants } from "../constants/global.constants";

declare var $;
declare var ShopifyBuy: any;
declare var EnjoyHint;
declare const google: any;
@Injectable({
  providedIn: "root",
})
export class GlobalService {
  public settings: any;
  showmanagement = false;
  isOpen: boolean = false
  displayManage = false;
  currentUser: any = "";
  currentRoute: any = "";
  isUpdateInvoice: any = false;
  invoiceObject: any = null;
  
  datePickerConfig = {
    isAnimated: true,
    dateInputFormat: "MM/DD/YYYY",
  };

  statesList: any[] = [
    { value: "AL", text: "Alabama" },
    { value: "AK", text: "Alaska" },
    { value: "AZ", text: "Arizona" },
    { value: "AR", text: "Arkansas" },
    { value: "CA", text: "California" },
    { value: "CO", text: "Colorado" },
    { value: "CT", text: "Connecticut" },
    { value: "DE", text: "Delaware" },
    { value: "DC", text: "District Of Columbia" },
    { value: "FL", text: "Florida" },
    { value: "GA", text: "Georgia" },
    { value: "HI", text: "Hawaii" },
    { value: "ID", text: "Idaho" },
    { value: "IL", text: "Illinois" },
    { value: "IN", text: "Indiana" },
    { value: "IA", text: "Iowa" },
    { value: "KS", text: "Kansas" },
    { value: "KY", text: "Kentucky" },
    { value: "LA", text: "Louisiana" },
    { value: "ME", text: "Maine" },
    { value: "MD", text: "Maryland" },
    { value: "MA", text: "Massachusetts" },
    { value: "MI", text: "Michigan" },
    { value: "MN", text: "Minnesota" },
    { value: "MS", text: "Mississippi" },
    { value: "MO", text: "Missouri" },
    { value: "MT", text: "Montana" },
    { value: "NE", text: "Nebraska" },
    { value: "NV", text: "Nevada" },
    { value: "NH", text: "New Hampshire" },
    { value: "NJ", text: "New Jersey" },
    { value: "NM", text: "New Mexico" },
    { value: "NY", text: "New York" },
    { value: "NC", text: "North Carolina" },
    { value: "ND", text: "North Dakota" },
    { value: "OH", text: "Ohio" },
    { value: "OK", text: "Oklahoma" },
    { value: "OR", text: "Oregon" },
    { value: "PA", text: "Pennsylvania" },
    { value: "RI", text: "Rhode Island" },
    { value: "SC", text: "South Carolina" },
    { value: "SD", text: "South Dakota" },
    { value: "TN", text: "Tennessee" },
    { value: "TX", text: "Texas" },
    { value: "UT", text: "Utah" },
    { value: "VT", text: "Vermont" },
    { value: "VA", text: "Virginia" },
    { value: "WA", text: "Washington" },
    { value: "WV", text: "West Virginia" },
    { value: "WI", text: "Wisconsin" },
    { value: "WY", text: "Wyoming" },
  ];

  private tokenValue: string = "";
  public loader: string = "hide";
  constructor(private storage: StorageService) { }
  config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'lg'
  };
  ScreenRatio: any = {
    Layout: "",
    AspectRation: "",
  };
  ScreenResolution: any = {
    ContentColor: "",
    ContentName: "",
    themeCode: "",
    themeName: "",
    height: "",
    width: "",
  };

  changeModalStyle(val: any) {
    if (
      document.getElementsByClassName("modal")[0] !== undefined &&
      document.getElementsByClassName("enjoyhint").length > 0
    ) {
      if (val && document.getElementsByClassName("modal")[0] !== undefined) {
        document.getElementsByClassName("modal")[0]["style"].zIndex = "100";
        document.getElementsByClassName("modal-backdrop")[0]["style"].zIndex =
          "5";
      } else {
        document.getElementsByClassName("modal")[0]["style"].zIndex = "";
        document.getElementsByClassName("modal-backdrop")[0]["style"].zIndex =
          "";
      }
    }
  }

  //wasim's work
  sortDataDESC(data: any, key: any) {
    data.sort((a, b) => {
      return b[key] - a[key];
    });
  }

  sortDataASC(data: any, key: any) {
    data.sort((a, b) => {
      return a[key] - b[key];
    });
  }

  searchDataByName(key: any, data: any, value: any) {
    value = value.trim();
    return data.filter((it) => {
      if (value === "") {
        return true;
      } else if (it[key] !== null) {
        if (it[key].toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
      }
    });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{​​​​​​​​3}​​​​​​​​)+(?!\d))/g, ",");
  }
  InitTour(config, key: any) {
    var enjoyhint_instance = new EnjoyHint({
      onStart: () => {
        //do something
        this.changeModalStyle(true);
      },
      onEnd: () => {
        this.changeModalStyle(false);
        document.getElementsByClassName("navbar")[0]["style"].zIndex = "";
        localStorage.setItem(key, "true");

        document.getElementsByClassName("mainCanvas").length > 0
          ? (document.getElementsByClassName("mainCanvas")[0]["style"].height =
            "60vh")
          : "";

        //do something
      },
      onSkip: () => {
        this.changeModalStyle(false);
        document.getElementsByClassName("navbar")[0]["style"].zIndex = "";
        localStorage.setItem(key, "true");

        document.getElementsByClassName("mainCanvas").length > 0
          ? (document.getElementsByClassName("mainCanvas")[0]["style"].height =
            "60vh")
          : "";
        //do something
      },
    });

    //set script config
    enjoyhint_instance.set(config);
    document.getElementsByClassName("navbar")[0]["style"].zIndex = -1;
    //run Enjoyhint script
    enjoyhint_instance.run();
  }

  tourIsEnabled(key) {
    if (localStorage.getItem(key) !== null) {
      let val = localStorage.getItem(key);
      if (val === "false") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  // for get model
  lgModal(className: any) {
    document.getElementsByClassName("modal-dialog")[0].classList.add(className);
    if (document.getElementsByClassName("modal-dialog")[1] !== undefined) {
      document
        .getElementsByClassName("modal-dialog")[1]
        .classList.add(className);
    }

    // document.getElementsByClassName('modal-dialog')[0]['style'].top = '100px';
  }
  // for get google map
  getMap(lat: any, lng: any, zoom: any) {
    let map;
    let marker;
    const DALLAS = { lat, lng };

    map = new google.maps.Map(document.getElementById("map"), {
      center: DALLAS,
      zoom,
    });

    marker = new google.maps.Marker({
      position: DALLAS,
      map,
      title: "Hello World!",
    });
    map.addListener("center_changed", () => {
      // 3 seconds after the center of the map has changed, pan back to the
      // marker.
      window.setTimeout(() => {
        map.panTo(marker.getPosition());
      }, 3000);
    });
    marker.addListener("click", () => {
      alert("Lat " + DALLAS.lat + " lng " + DALLAS.lng + "");
      map.setZoom(zoom);
      map.setCenter(marker.getPosition());
    });
  }
  showLoader(target: any) {
    let html =
      '<div class="card-disabled"><div class="card-portlets-loader"></div></div>';
    $(target).append(html);
  }
  HideLoader(target: any) {
    $(".card-disabled").remove();
  }
  showDangerNotification(target: any, message: any, title: any) {
    // tslint:disable: max-line-length
    // const html = '<div class="jq-toast-wrap top-right"><div class="jq-toast-single jq-has-icon jq-icon-error" style="text-align: left; "> <h2 class="jq-toast-heading">' + title + '</h2>' + message + '</div></div>';
    const html =
      '<div data-notify="container" class="col-11 col-sm-3 alert alert-danger animated fadeInDown" role="alert" data-notify-position="top-right" style="display: inline-block; margin: 0px auto; position: fixed; transition: all 0.5s ease-in-out 0s; z-index: 1031; top: 105px; right: 50px; animation-iteration-count: 1;"> <span data-notify="title">' +
      title +
      '</span> <span data-notify="message">' +
      message +
      "</span></div>";
    $(target).append(html);
  }
  showSuccessNotification(target: any, message: any, title: any) {
    // tslint:disable-next-line: max-line-length
    const html =
      '<div class="jq-toast-wrap top-right"><div class="jq-toast-single jq-has-icon jq-icon-success" style="text-align: left; "> <h2 class="jq-toast-heading">' +
      title +
      "</h2>" +
      message +
      "</div></div>";
    $(target).append(html);
  }
  showWarningNotification(target: any, message: any, title: any) {
    // tslint:disable-next-line: max-line-length
    const html =
      '<div class="jq-toast-wrap top-right"><div class="jq-toast-single jq-has-icon jq-icon-error" style="text-align: left; "> <h2 class="jq-toast-heading">' +
      title +
      "</h2>" +
      message +
      "</div></div>";
    $(target).append(html);
  }
  showInfoNotification(target: any, message: any, title: any) {
    const html =
      '<div class="jq-toast-wrap top-right"><div class="jq-toast-single jq-has-icon jq-icon-info" style="text-align: left; "> <h2 class="jq-toast-heading">' +
      title +
      "</h2>" +
      message +
      "</div></div>";
    $(target).append(html);
  }
  hideNotification() {
    $(".alert").remove();
  }
  //token getter
  get token(): string {
    let userdata = this.storage.getLocalStorage(GlobalConstants.token);
    let user = userdata == null ? "" : userdata;
    if (user != null) {
      this.tokenValue = user;
    } else {
      this.tokenValue = "";
    }
    return this.tokenValue;
  }
  // overflowAdd() {
  //     document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
  // }
  // overflowRemove() {
  //     document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
  // }
  lastUserView() {
    if (localStorage.getItem("userSetting") !== null) {
      return JSON.parse(localStorage.getItem("userSetting"));
    } else {
      return false;
    }
  }
  lastContentView() {
    if (localStorage.getItem("ContentApproval") !== null) {
      return JSON.parse(localStorage.getItem("ContentApproval"));
    } else {
      return false;
    }
  }
  lastThemeView() {
    if (localStorage.getItem("themeForm") !== null) {
      return JSON.parse(localStorage.getItem("themeForm"));
    } else {
      return false;
    }
  }
  dashboard() {
    if (localStorage.getItem("dashboard") !== null) {
      return JSON.parse(localStorage.getItem("dashboard"));
    } else {
      return false;
    }
  }
  lastCreateDeviceView() {
    if (localStorage.getItem("deviceCreate") !== null) {
      return JSON.parse(localStorage.getItem("deviceCreate"));
    } else {
      return false;
    }
  }
}

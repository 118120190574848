<div class="menu">
  <div class="main-menu">
    <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
      <ng-content></ng-content>

    </perfect-scrollbar>
  </div>
  <!-- <div class="sub-menu">
    <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">

    </perfect-scrollbar>
  </div> -->
</div>
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../shared/services/AuthGuard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("../Layout/layout.module").then((m) => m.LayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("../login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "login-page",
    loadChildren: () =>
      import("../login-page/login-page.module").then((m) => m.LoginPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

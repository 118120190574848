import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";
declare var $;
@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate() {
    if (localStorage.getItem("SeesionUser") == "admin") {
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}

export module GlobalConstants {
  //export const SERVER_URL = "https://192.168.50.94:45455/api/"; // Dev Link

  export const SERVER_URL = "https://simpelloprod.azurewebsites.net/api/";
  // export const SERVER_URL1 = "https://simpelloprod.azurewebsites.net/api/";

  export const config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
  }; // Config for bootstrap modal.
  export const token = "jwtToken"; // Token
}

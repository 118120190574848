export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to?: string;
  name?: string;
  subs?: IMenuItem[];
  bool: boolean;
}

const data: IMenuItem[] = [
  {
    icon: "iconsminds-user",
    label: "Audit Details",
    name: "User Management",
    to: "/audit-details",
    bool: false,
  },
  // {
  //   icon: "iconsminds-user",
  //   label: "Analytics",
  //   name: "User Management",
  //   to: "/analytics",
  //   bool: false,
  // }
  // {
  //   icon: "iconsminds-user",
  //   label: "Books",
  //   name: "User Management",
  //   to: "/categories",
  //   bool: false,
  // }

  //

  //
  // {
  //   icon: "simple-icon-settings",
  //   name: "Settings",
  //   label: "Settings",
  //   bool: true,
  //   to: "/settings",
  //   subs: [
  //     // {
  //     //   icon: 'iconsminds-handshake',
  //     //   name: 'Settings',
  //     //   label: 'Dealerships',
  //     //   to: '/settings/site',
  //     //   bool: true,
  //     // },
  //     {
  //       icon: "simple-icon-settings",
  //       name: "Settings",
  //       label: "Complaint Config",
  //       to: "/settings/complaint-config",
  //       bool: true,
  //     },
  //   ],
  // },
  // {
  //   icon: 'simple-icon-list',
  //   name: 'Survey',
  //   label: 'Survey',
  //   to: '/app/ui',
  //   bool: true,
  // },
  // {
  //   icon: 'iconsminds-notepad',
  //   name: 'Reports',
  //   label: 'Reports',
  //   to: '/reports',
  //   bool: true,
  // },
  // {
  //   icon: 'simple-icon-tag',
  //   name: 'Tags',
  //   label: 'Tags',
  //   to: '/tags',
  //   bool: true,
  // },
];
export default data;

import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './root-component/app.component';
import { SharedModule } from '../shared/modules/shared.module';
import { ModalModule } from "ngx-bootstrap/modal";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AgGridModule } from "ag-grid-angular";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuardServiceService } from '../authGuardService.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    PerfectScrollbarModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ModalModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    AgGridModule,
    FormsModule,
  ReactiveFormsModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [{
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
  },
  AuthGuardServiceService
],
  bootstrap: [AppComponent],
})
export class AppModule {}
